import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika'

import Home from '@/views/home.vue'
import CatalogSections from '@/views/catalog/catalog.sections.vue'
import CatalogSection from '@/views/catalog/catalog.section.vue'
import CatalogSearch from '@/views/catalog/catalog.search.vue'
import CatalogConsist from '@/views/catalog/catalog.consist.vue'
import CatalogProduct from '@/views/catalog/catalog.product.vue'
import Menu from '@/views/menu.vue'
import Cart from '@/views/cart.vue'
import Favorites from '@/views/favorites.vue'
import AuthLogin from '@/views/auth/login.vue'
import AuthRegister from '@/views/auth/register.vue'
import AuthRestore from '@/views/auth/restore.vue'
import Order from '@/views/order.vue'
import OrderConfirm from '@/views/order.confirm.vue'
import OrderPay from '@/views/order.pay.vue'
import OrderList from '@/views/order.list.vue'
import OrderDetail from '@/views/order.detail.vue'

import ScannerBarcode from '@/views/scanner.barcode.vue'

import Help from '@/views/help/index.vue'
import HelpDelivery from '@/views/help/delivery.vue'
import HelpPayment from '@/views/help/payment.vue'

import Clients from '@/views/clients/index.vue'
import ActionsList from '@/views/clients/actions.list.vue'
import ActionsDetail from '@/views/clients/actions.detail.vue'
import Reviews from "@/views/clients/reviews.vue"
import Pic from '@/views/clients/pic.vue'
import PicQr from '@/views/clients/pic.qr.vue'
import RecipesList from "@/views/clients/recipes.list.vue"
import RecipesDetail from "@/views/clients/recipes.detail.vue"
import ShopServiceQuality from "@/views/clients/shop.service.quality.vue"

import AboutIndex from '@/views/about/index.vue'
import AboutContacts from '@/views/about/contacts.vue'
import AboutNewsList from '@/views/about/news.list.vue'
import AboutNewsDetail from '@/views/about/news.detail.vue'
import AboutShops from '@/views/about/shops.vue'
import AboutJob from '@/views/about/job.vue'
import AboutArticlesList from '@/views/about/articles.list.vue'
import AboutArticlesDetail from '@/views/about/articles.detail.vue'
import AboutAwards from '@/views/about/awards.vue'

import Profile from '@/views/personal/profile.vue'
import Purchase from '@/views/personal/purchase.list.vue'
import PurchaseDetail from '@/views/personal/purchase.detail.vue'
import Bonuses from '@/views/personal/bonuses.vue'
import OrderTracking from '@/views/personal/order.tracking.vue'
import PushSettings from '@/views/personal/push.vue'
import TermsOfUse from '../views/terms.of.use.vue'
import Invite from '@/views/personal/invite.vue'
import InviteTerms from '@/views/personal/invite.terms.vue'
import ResultYear from '@/views/personal/result.year.vue'

import Region from '@/views/region.vue'

const routes = [
  //Главный экран
  {
    path: '/',
    name: 'home',
    component: Home,
    props: route => ({ affiliate: route.params.affiliate ? route.params.affiliate : "" }),
    beforeEnter(to, from, next) {
      if (to.query.pic !== undefined) {
        next({ name: 'pic-qr', params: { code: to.query.pic } });
      } else {
        next();
      }
    }
    
  },

  //Ссылка приглашения друга
  {
    path: '/affiliate/:code/',
    name: 'affiliate',
    component: Home,
    beforeEnter(to, from, next) {

      //запоминаем данные аффилиата в сесии
      store.dispatch("setSessionParam", { key: "B1T_OKRAINA_AFFILIATE", value: to.params.code  })
      if (to.query.hash !== undefined) {
        store.dispatch("setSessionParam", { key: "B1T_OKRAINA_AFFILIATE_HASH", value: to.query.hash  })
      }

      //переход на главный экран
      next({ name: 'home', params: { }, replace: true });
    }
  },


  // --------------------------
  // Каталог
  // --------------------------

  //индексная
  {
    path: '/catalog/',
    name: 'catalog',
    component: CatalogSections,
    beforeEnter(to, from, next) {
      if (to.query.q !== undefined) {
        next({ name: 'catalog-search', params: { q: to.query.q, focus: false}, replace: true });
      } else {
        next();
      }
    }
  },
  //поиск
  {
    path: '/catalog/?q=:q?&f=:focus?',
    name: 'catalog-search',
    component: CatalogSearch,
    props: route => ({ q: route.params.q ? route.params.q : "", focus: route.params.focus == "Y" })
  },
  //товар
  {
    path: '/catalog/product/:code/',
    name: 'catalog-product',
    component: CatalogProduct,
    props: true
  },
  //раздел
  {
    path: '/catalog/:path+/',
    name: 'catalog-section',
    component: CatalogSection,
    props: route => ({ path: route.params.path ? route.params.path : [], basketId :route.query.basket ? route.query.basket: ""  }),
    beforeEnter(to, from, next) {
      //если переход к поиску по составу
      if (to.params.path && to.params.path.join("") == "sostav") {
        next({ name: 'catalog-consist', params: { code: to.query.code }, replace: true });
      } else {
        next();
      }

    }
  },

  //поиск по составу
  {
    path: '/catalog/sostav/?code=:code?',
    name: 'catalog-consist',
    component: CatalogConsist,
    props: route => ({ code: route.params.code ? route.params.code : "" })
  },

  // --------------------------
  // Личный кабинет
  // --------------------------

  //меню
  {
    path: '/personal/',
    name: 'menu',
    component: Menu
  },
  //корзина
  {
    path: '/personal/cart/',
    name: 'cart',
    component: Cart
  },
  //избранное 
  {
    path: '/personal/favorites/',
    name: 'favorites',
    component: Favorites
  },
  //настройки профиля
  {
    path: '/personal/profile/',
    name: 'profile',
    component: Profile,
    meta: {requiresAuth: true, noCache: true, hideMenu: true}
  },
  //бонусные баллы
  {
    path: '/personal/bonuses/',
    name: 'bonuses',
    component: Bonuses,
    meta: {requiresAuth: true, noCache: false, hideMenu: false}
  },
  //оформление заказа
  {
    path: '/personal/order/make/?ORDER_ID=:id',
    name: 'order-confirm',
    component: OrderConfirm,
    props: route => ({ id: route.params.id ? route.params.id : "" }),
    meta: {noCache: true}
  },
  {
    path: '/personal/order/make/',
    name: 'order',
    component: Order,
    beforeEnter(to, from, next) {
      const { ORDER_ID = null } = to.query;  
      
      if (ORDER_ID > 0) {
        next({ name: 'order-confirm', params: { id: ORDER_ID }, replace: true });
      } else {
        next();
      }
    }
  },
  //Список покупок в оффлайн магазинах
  {
    path: '/personal/purchases/',
    name: 'purchase',
    component: Purchase,
    meta: {requiresAuth: true, noCache: false, hideMenu: true}
  },
  //Покупка в оффлайн магазине - детальная
  {
    path: '/personal/purchases/detail/:id/',
    name: 'purchase-detail',
    component: PurchaseDetail,
    props:true,
    meta: {requiresAuth: true, noCache: true, hideMenu: true}
  },
  //оплата заказа
  {
    path: '/personal/order/payment/',
    name: 'order-pay',
    component: OrderPay,
    props: route => ({ 
      id: (route.params.id ? route.params.id : (route.query.ORDER_ID ? route.query.ORDER_ID : "")), 
      check: (route.params.check ? route.params.check : (route.query.check ? route.query.check : "")) 
    }),
    meta: {noCache: true, hideMenu: true}
  },
  //отслеживание заказа (по ИД заказа)
  {
    path: '/personal/order/:orderId/tracking/',
    name: 'order-tracking',
    component: OrderTracking,
    props: true,
    meta: {noCache: true}
  },
  //отслеживание заказа (по хэшу)
  {
    path: '/m/:hash',
    name: 'order-tracking-hash',
    component: OrderTracking,
    props: true,
    meta: {noCache: true}
  },
  //Настройка уведомлений
  {
    path: '/personal/push/',
    name: 'personal-push',
    component: PushSettings,
    meta: {noCache: true, hideMenu: true}
  },
  //Заказы - список
  {
    path: '/personal/order/',
    name: 'order-list',
    component: OrderList,
    meta: {requiresAuth: true, hideMenu: true}
  },
  //Заказы - детальная
  {   
    path: '/personal/order/detail/:id/',
    name: 'order-detail',
    component: OrderDetail,
    props: true,
    meta: {requiresAuth: true, hideMenu: true}
  },
  //Пригласить друга
  {   
    path: '/personal/invite/',
    name: 'invite',
    component: Invite,
    meta: {requiresAuth: true, hideMenu: true}
  },
  //Пригласить друга - условия
  {   
    path: '/personal/invite/terms/',
    name: 'invite-terms',
    component: InviteTerms,
    meta: {noCache: true, hideMenu: true}
  },
  //Результаты года
  {   
    path: '/personal/result-year/',
    name: 'personal-result-year',
    component: ResultYear,
    meta: {requiresAuth: true, noCache: true, hideMenu: true}
  },
  

  // --------------------------
  // Помощь
  // --------------------------

  
  // индексная
  {
    path: '/help/',
    name: 'help',
    component: Help,
    meta: {noCache: true, hideMenu: true}
  },
  // доставка
  {
    path: '/help/delivery/',
    name: 'help-delivery',
    component: HelpDelivery,
    meta: {noCache: true, hideMenu: true}
  },
  // оплата
  {
    path: '/help/payment/',
    name: 'help-payment',
    component: HelpPayment,
    meta: {noCache: true, hideMenu: true}
  },

  // --------------------------
  // Клиентам
  // --------------------------

  // индексная
  {
    path: '/clients/',
    name: 'clients',
    component: Clients,
    meta: {noCache: true, hideMenu: true}
  },

  // отзывы о работе интернет магазина
  {
      path: '/clients/actions/shop-service-quality/',
      name: 'shop-service-quality',
      component: ShopServiceQuality,
      meta: {hideMenu: true}
  },
  // акции
  {
    path: '/clients/actions/',
    name: 'actions-list',
    component: ActionsList,
    meta: {hideMenu: true}
  },
  // акция детально
  {
    path: '/clients/actions/:code/',
    name: 'actions-detail',
    component: ActionsDetail,
    props: true,
    meta: {hideMenu: true}
  },

  // PIC
  {
    path: '/clients/pic/',
    name: 'pic',
    component: Pic,
    meta: {hideMenu: true},
    beforeEnter(to, from, next) {
      const { pic = null } = to.query;  
         
      if (pic) {
        next({ name: 'pic-qr', params: { code: pic }, replace: true });
      } else {
        next();
      }
    }
  },
  // PIC код
  {
    path: '/clients/pic/?pic=:code',
    name: 'pic-qr',
    component: PicQr,
    props: true,
    meta: {hideMenu: true}
  },
  // PIC редирект
  {
    path: '/pic/:code',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/clients/pic/', query: { pic: to.params.code } }
    },
  },
  // PIC редирект
  {
    path: '/clients/pic/:code',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/clients/pic/', query: { pic: to.params.code } }
    },
  },

  // Отзывы о магазине
  {
    path: '/clients/reviews/',
    name: 'reviews',
    component: Reviews,
    meta: {noCache: true, hideMenu: true}
  },
  
  // Рецепты
  {
    path: '/clients/recipes/',
    name: 'recipes-list',
    component: RecipesList,
    meta: {hideMenu: true}
  },
  // реуепт детально
  {
    path: '/clients/recipes/:code/',
    name: 'recipes-detail',
    component: RecipesDetail,
    props: true,
    meta: {hideMenu: true}
  },
  

  // --------------------------
  // О компании
  // --------------------------

  // о нас
  {
    path: '/about/',
    name: 'about',
    component: AboutIndex,
    meta: {noCache: true, hideMenu: true}
  },
  // контакты
  {
    path: '/about/contacts/',
    name: 'about-contacts',
    component: AboutContacts,
    meta: {noCache: true, hideMenu: true}
  },
  //Список новостей
  {
    path: '/about/news/',
    name: 'news-list',
    component: AboutNewsList,
    meta: {hideMenu: true}
  },
  // новость детально
  {
    path: '/about/news/:code/',
    name: 'news-detail',
    component: AboutNewsDetail,
    props: true,
    meta: {hideMenu: true}
  },
  // фирменные магазины
  {
    path: '/about/shops/',
    name: 'about-shops',
    component: AboutShops,
    meta: {noCache: true, hideMenu: true}
  },
  // вакансии
  {
    path: '/about/job/',
    name: 'about-job',
    component: AboutJob,
    meta: {noCache: true, hideMenu: true}
  },
  //блог
  {
    path: '/articles/',
    name: 'articles',
    component: AboutArticlesList,
    meta: {hideMenu: true}
  },
  //блог - статья детально
  {
    path: '/articles/:code/',
    name: 'articles-detail',
    component: AboutArticlesDetail,
    props: true,
    meta: {hideMenu: true}
  },
  //награды
  {
    path: '/about/awards/',
    name: 'about-awards',
    component: AboutAwards,
    meta: {noCache: true, hideMenu: true}
  },

  //
  // Прочее
  //

  //Вход
  {
    path: '/login/',
    name: 'login',
    component: AuthLogin,
    meta: {hideMenu: true}
  },
  //Регистрация
  {
    path: '/register/',
    name: 'register',
    component: AuthRegister,
    meta: {hideMenu: true}
  },
  {
    path: '/restore/',
    name: 'restore',
    component: AuthRestore,
    meta: {hideMenu: true}
  },

  //Сканер штрихкодов
  {
    path: '/barcode-scanner/',
    name: 'barcode-scanner',
    component: ScannerBarcode,
    meta: {hideMenu: true}
  },

  //Согласие с правилами
  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    component: TermsOfUse,
    meta: { hideMenu: true, noCache: true }
  },
  //Выбор региона (начальный экран)
  {
    path: '/region/',
    name: 'region',
    component: Region,
    meta: { hideMenu: true }
  },


];

//подписываемся на событие "возвтрата" (обязательно до инициализации router)
window.addEventListener('popstate', (event) => {

  //если есть колбэки на возврат
  if (store.getters.getBackStack.length > 0) {
    let callback = store.getters.getBackStack[store.getters.getBackStack.length - 1];
    callback();
    //запрещаем router'у реагировать на этот возврат
    event.stopImmediatePropagation();
  } else {
    store.commit('setIsNowGoBack', true);
  }
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})


//обработка события начала перехода
router.beforeEach((to) => {

  //закроем модальое окно товара (если вдруг открыто)
  bus.emit("OKRAINA_PRODUCT_MODAL_E_CLOSE");

  //проверка выбора региона
  if(to.name != "region") {
    if(!store.getters.getCurrentConnectionKey) {
      return {
        path: '/region/'
      }
    }
  }
  

  //проверка на принятие правил использования
  if(to.name != "terms-of-use" && to.name != "region") {
    if((store.getters.isNativePlatform || store.getters.getLaunchPlatform == 'vk') && !store.getters.getTermsOfUseAccepted) {
      return {
        path: '/terms-of-use/'
      }
    }
  }

  //проверка необходимости авторизации
  if (to.meta.requiresAuth && !store.getters.isAuthorized) {
    return {
      path: '/login'
    }
  }

  //скрыть ли меню
  store.commit('setHideMenu', to.meta.hideMenu == true);
});

//обработка события завершения перехода
router.afterEach((to, from) => {

  //если не начальный экран инициализции приложения
  //(чтобы при загрузке переход к первому экрану был без анимации)
  if (from.name) {
    //если один из экранов не входит в меню
    if (!store.getters.isRouteInMenu(to.name) || !store.getters.isRouteInMenu(from.name)) {

      //если в данный момент идёт переход назад
      if(store.getters.getIsNowGoBack) {
        to.meta.transitionName = "slide-right";
      } else {

        //если экран в который переходим в меню,
        //то значит экран из которого переходим не в меню
        //и нужна анимация как буд-то идёт возврат назад
        if(store.getters.isRouteInMenu(to.name)) {
          to.meta.transitionName = "slide-right";
        } else {
          to.meta.transitionName = "slide-left";
        }
      }

    } else {
      //иначе анимация не нужна
      to.meta.transitionName = "";
    }
  } else {
    to.meta.transitionName = "";
  }

  store.commit('setIsNowGoBack', false);

  //хит в Яндекс Метрику
  metrika.hit(window.location.pathname +window.location.search, {});
})

export default router
