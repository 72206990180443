import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from '@capacitor/core';
import store from '@/store'
import rest from "@/plugins/rest"
import bus from '@/plugins/bus'
import router from '@/router'
import errorListiner from '@/plugins/error.listiner'
import metrika from '@/plugins/yandex.metrika'

const push = {

    currentTopic: "",

    init() {

        //обработка события регистрации
        PushNotifications.addListener('registration', token => {

            //хак для ios - token возвращается APNs, а не FCM
            if(Capacitor.getPlatform() == "ios") {
                //используем другой плагин - FCM
                if(Capacitor.isPluginAvailable("FCM")) {
                    //получаем токен FCM
                    FCM.getToken().then((r) => {
                        this.onTokenRefresh(r.token);
                    });
                }

            } else {
                this.onTokenRefresh(token.value);
            }
            
        });

        //обработка события ошибки регистрации
        PushNotifications.addListener('registrationError', err => {
            bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: err.error });
        });

        //подписываемся на получение сообщения (работает только для ios - для android у нас переписана нативная часть)
        PushNotifications.addListener('pushNotificationReceived', notification => {
            //покажем сообщение в области уведомлений
            bus.emit('OKRAINA_NOTIFICATION_E_SHOW', notification.data);
            //отправим событие всем подписанным компонентам
            bus.emit('OKRAINA_PUSH_E_RECEIVE', notification.data);
        });

        //подписываемся на клик по сообщению в области уведомлений
        PushNotifications.addListener('pushNotificationActionPerformed', event => {
            this.onPushClick(event.notification.data);
        });

        this.register();

        //подписываемся на запрос удаления токена с сервера
        bus.on("OKRAINA_PUSH_E_DELETE_TOKEN_FROM_SERVER", this.deleteTokenFromServer);
        //подписываемся на запрос сохранения токена на сервере
        bus.on("OKRAINA_PUSH_E_SAVE_TOKEN_TO_SERVER", this.saveTokenToServer);
        //подписываемся на запрос подписки на топик
        bus.on("OKRAINA_PUSH_E_SUBSCRIBE", this.subscribe);
        //подписываемся на запрос отписки от топика
        bus.on("OKRAINA_PUSH_E_UNSUBSCRIBE", this.unSubscribe);
    },

    /**
     * Производит регистрацию и проверку доступа
     */
    async register() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            return;
            //throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    },

    /**
    * Обработка изменения токена
    */
    onTokenRefresh(token) {

        let oldToken = store.getters.getPushToken;

        //сохраним токен в текущем состоянии приложения
        store.commit('setPushToken', token);

        //сохраним токен на сервере
        this.saveTokenToServer(oldToken);

        //подпишемся на новости/акции
        this.subscribe();

    },

    /**
    * Сохраняет токен на сервере
    */
    saveTokenToServer() {

        //сохраняем только в случае если пользователь авторизован и имеется FCM токен
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.add", {
                method: 'post',
                data: {
                    TOKEN: store.getters.getPushToken,
                    PLATFORM: Capacitor.getPlatform()
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.SUCCESS) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.ERROR_TEXT
                        });
                    }

                })
                .catch(() => {
                    //в тихом режиме не обрабатываем ошибку
                });
        }

    },

    /**
    * Удаление
    */
    deleteTokenFromServer() {

        //только если токен имеется и пользователь авторизован
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.delete", {
                method: 'post',
                data: {
                    TOKEN: store.getters.getPushToken
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.SUCCESS) {
                        errorListiner.onError({
                            message: "Ошибка удаления push токена: " +data.ERROR_TEXT
                        });
                    }

                })
        }

    },

    /**
     * Полуает топик
     */
    getTopic() {
        
        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise(resolve => {

            //при переключении региона настройки сайта будут сбрасываться и топик опять будет пустой
            //что будет приводить к ожиданию топика и дальше по кругу
            let topic = store.getters.getPushTopic;
            
            //если настройки уже прогрузились и топик известен
            if(topic) {
                resolve(topic);
            } else {

                //следим за изменением топика
                const watcher = store.watch((state,getters) => { return getters.getPushTopic }, (newVal) => {
                    //если поменялся 
                    if (newVal) {
                        //прекращаем отслеживание
                        watcher();
                        resolve(newVal);
                    }
                });
            }

        });
    },

    /**
     * Подписка на топик
     */
    subscribe() {
        push.getTopic().then((topic) => {
            
            push.subscribeToImmediatly(topic);
            //запоминаем топик, чтобы потом можно было отписаться, не получая из store топик
            //ведь получение getTopic() идёт через promise, что потенциально может создать проблемы
            push.currentTopic = topic;
        });

    },

    /**
     * Подписка на топик
     */
    subscribeToImmediatly(topic) {

        //используем другой плагин - FCM
        if(Capacitor.isPluginAvailable("FCM")) {
            FCM.subscribeTo({ topic: topic })
            .catch((err) => console.log(err));
        }
    },

    /**
     * Отписка от топика
     */
    unSubscribe() {

        if(push.currentTopic) {
            push.unSubscribeImmediatly(push.currentTopic);
        }

    },

    /**
     * Отписка от топика
     */
    unSubscribeImmediatly(topic) {

        //используем другой плагин - FCM
        if(Capacitor.isPluginAvailable("FCM")) {
            FCM.unsubscribeFrom({ topic: topic })
            .catch((err) => console.log(err));
        }
    },

    /**
     * Действие при клике на уведомление
     */
    async onPushClick(data) {

        //ждём чтобы приложение было готово (иначе переход просто не сработает)
        await this.waitAppReady();
        //в общем состоянии помечаем, что был кликнут push
        store.commit("setPushClicked", true);

        if(data.TYPE) {
            if(data.TYPE == 'ACTION') {
                this.goToAction(data.ID);
            } else if(data.TYPE == 'NEWS') {
                this.goToNews(data.ID);
            } else if(data.TYPE == 'CATALOG_SECTION') {
                this.goToCatalogSection(data.ID);
            } else if(data.TYPE == 'CATALOG_PRODUCT') {
                this.goToCatalogProduct(data.ID);
            } else if(data.TYPE == 'ORDER') {
                this.goToOrder(data.ID);
            } else if(data.TYPE == 'ORDERS') {
                this.goToOrders();
            } else if (data.TYPE  == 'TRACKING') {
                this.goToOrderTracking(data.ID);
            } else if (data.TYPE  == 'MENU_ITEM') {
                this.goToMenuItem(data.NEW_ID);
            }
        }

        if(data.NOTIFICATION_ID > 0) {
            //событие в ЯндексМетрику
            metrika.reachGoal("push-" + data.NOTIFICATION_ID);
        }

        setTimeout(() => {
            //в общем состоянии помечаем, что клик по пуш уже прошёл
            store.commit("setPushClicked", false);
        },4000);
    },

    /**
     * Переход к акции
     */
    goToAction(id) {

        rest.call("actions.getbyid", {
            method: "post",
            data: {
                ID: id
            },
        })
        .then((data) => {
            if (data.SUCCESS) {
                router.push({ path: data.FIELDS.DETAIL_PAGE_URL });
            } else {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
        })
               
    },

    /**
     * Переход к новости
     */
    goToNews(id) {

        rest.call("news.getbyid", {
            method: "post",
            data: {
                ID: id
            },
        })
        .then((data) => {
            if (data.SUCCESS) {
                router.push({ name: "news-detail", params: { code: data.FIELDS.CODE } });
            } else {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
        })
               
    },

    /**
     * Переход к разделу каталога
     */
    goToCatalogSection(id) {

        rest.call("catalog.sections.getbyid", {
            method: "post",
            data: {
                ID: id
            },
        })
        .then((data) => {
            if (data.SUCCESS) {
                router.push({ path: data.SECTION_PAGE_URL });
            } else {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
        })
               
    },

    /**
     * Переход к товару
     */
    goToCatalogProduct(id) {

        rest.call("catalog.products.getbyid", {
            method: "post",
            data: {
                ID: id
            },
        })
        .then((data) => {
            if (data.SUCCESS) {
                bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                    id: id,
                    code: data.FIELDS.CODE,
                    recommendationId: ""
                });
            } else {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
        })
               
    },

    /**
     * Переход к заказу
     */
    goToOrder(id) {
        router.push({ path: "/personal/order/detail/"+id+"/"});
    },

    /**
     * Переход к списку заказов
     */
    goToOrders() {
        router.push({ path: "/personal/order/"});
    },

    /**
     * Переход к отслеживанию заказа
     */
    goToOrderTracking(id) {
        router.push({ path: "/personal/order/"+id+"/tracking/"});
    },

    /**
     * Переход по пункту меню
     */
    goToMenuItem(id) {

        if(!id) {
            return;
        }

        rest.call("system.menu", {
            method: "post",
            data: {
                PLATFORM: store.getters.getPlatform
            },
        })
        .then((data) => {
            if (data.SUCCESS) {
                
                let item = data.ITEMS.find(item => item.FIELDS.ID == id);
                if(item) {
                    router.push({ path: item.PROPS.HREF});
                }

            } else {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
        })

    },

    /**
     * Ждёт готовности приложения
     */
    waitAppReady() {

        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise(resolve => {

            let appReady = store.getters.getAppReady;
            
            //если приложение уже готово
            if(appReady) {
                resolve(appReady);
            } else {

                //следим за изменением флага
                const watcher = store.watch((state,getters) => { return getters.getAppReady }, (newVal) => {
                    //если поменялся 
                    if (newVal) {
                        //прекращаем отслеживание
                        watcher();
                        resolve(newVal);
                    }
                });
            }

        });
    }

    
}

export default push;