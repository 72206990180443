<template>
  <div class="okraina-ci" :class="{ slim: slim, big: big }">

    <div class="stickers">
      <okraina-catalog-product-stickers :item="item" />
    </div>

    <div class="photo" @click="goToProduct">

      <okraina-catalog-product-photo :item="selected.FIELDS.DETAIL_PICTURE ? selected : item" :slim="slim" :big="big" />

      <okraina-catalog-product-not-available v-if="!canBuy && item.PRICE" :item="item" />

      <div class="experation-date" v-if="canBuy">
        <okraina-catalog-product-experation-date :item="item" />
      </div>

    </div>

    <div class="inner">
      <div class="rat-fav">
        <div>
          <okraina-catalog-product-rating :rating="item.PROPS.rating" />
        </div>
        <div>
          <div class="fav">
            <okraina-catalog-product-favorite-btn :productId="item.FIELDS.ID" />
          </div>
        </div>
      </div>

      <div class="name" @click="goToProduct">
        {{ item.FIELDS.NAME }}
      </div>

      <div class="price-basket">

        <div class="item-price">
          <okraina-catalog-product-price :item="selected" v-if="item.PRICE" :isRifling="isRiflingSelected" />
        </div>

        <div class="basket">
          <template v-if="canBuy">
            <okraina-catalog-product-buy-btn :productId="basketData.productId" :params="basketData.params"
              :checkQuantity="checkQuantity" :maxQuantity="maxQuantity" :maxQuantityMessage="maxQuantityMessage"
              :recommendationId="recommendationId" :listMode="true" :hasRifling="hasRifling"
              @openRiflingSelect="openRiflingSelect" :preorder="preorder" />
          </template>
          <template v-else-if="canNeedMore">
            <okraina-catalog-product-needmore-btn :productId="item.FIELDS.ID" v-model="inNeedMoreList" />
          </template>
          <template v-else-if="canSubscribe">
            <okraina-catalog-product-subscribe-btn :productId="item.FIELDS.ID" v-model="subscribed" />
          </template>

        </div>

      </div>

    </div>
  </div>

  <okraina-catalog-product-rifling-select v-if="hasRifling" v-model:visible="showRiflingSelect" :item="item"
    :recommendationId="recommendationId" />
</template>

<script>
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaCatalogProductRating from "@/components/catalog/product.rating.vue";
import okrainaCatalogProductStickers from "@/components/catalog/product.stickers.vue";

import okrainaCatalogProductExperationDate from "@/components/catalog/product.experation.date.vue";
import okrainaCatalogProductFavoriteBtn from "@/components/catalog/product.favorite.btn.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";
import okrainaCatalogProductBuyBtn from "@/components/catalog/product.buy.btn.vue";
import okrainaCatalogProductNeedmoreBtn from "@/components/catalog/product.needmore.btn.vue";
import okrainaCatalogProductSubscribeBtn from "@/components/catalog/product.subscribe.btn.vue";
import okrainaCatalogProductNotAvailable from "@/components/catalog/product.notavailable.vue";
import okrainaCatalogProductRiflingSelect from "@/components/catalog/product.rifling.select.vue";

import okrainaCatalogItemMixin from "@/mixins/catalog.item.js"

import bus from "@/plugins/bus";

export default {
  name: "okraina-catalog-item",
  mixins: [okrainaCatalogItemMixin],
  components: {
    okrainaCatalogProductPhoto,
    okrainaCatalogProductRating,
    okrainaCatalogProductStickers,
    okrainaCatalogProductExperationDate,
    okrainaCatalogProductFavoriteBtn,
    okrainaCatalogProductPrice,
    okrainaCatalogProductBuyBtn,
    okrainaCatalogProductSubscribeBtn,
    okrainaCatalogProductNotAvailable,
    okrainaCatalogProductRiflingSelect,
    okrainaCatalogProductNeedmoreBtn
  },
  data() {
    return {
      subscribed: this.item.CATALOG_SUBSCRIBE.SUBSCRIBED == "Y",
      inNeedMoreList: this.item.NEED_MORE && this.item.NEED_MORE.IN_LIST == "Y",
      showRiflingSelect: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    /**
     * узкая карточка - товар в слайдере товаров
     */
    slim: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /**
     * большая карточка - например товар дня (один в строке)
     */
    big: {
      type: Boolean,
      default() {
        return false;
      },
    },
    recommendationId: {
      type: [String, Number],
      default() {
        return "";
      }
    }
  },
  computed: {

    //см миксин okrainaCatalogItemMixin

  },
  methods: {
    goToProduct() {
      bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
        id: this.item.FIELDS.ID,
        code: this.item.FIELDS.CODE,
        recommendationId: this.recommendationId
      });

    },

    openRiflingSelect() {
      this.showRiflingSelect = true;
    }

  },

};
</script>

<style lang="scss">
.okraina-ci {
  position: relative;
  border-radius: 5px;
  background-color: var(--color-block-background);
  height: 100%;

  .stickers {
    position: absolute;
    top: -5px;
    left: 4px;
    right: -4px;
    z-index: 20;
  }

  .photo {
    position: relative;
    cursor: pointer;
  }

  .experation-date {
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .inner {
    padding: 8px;
    padding-top: 0px;
  }

  .rat-fav {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    align-items: center;
    margin-bottom: 4px;

    .fav {
      float: right;
      line-height: 1;
    }
  }

  .name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--color-text-dark);
    margin-bottom: 5px;

    cursor: pointer;

    height: calc(4.2em * var(--text-scale, 1));
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;

  }

  .item-price {
    margin-bottom: 15px;
  }


  &.slim {
    border-radius: 5px;
    height: auto;

    .name {
      height: calc(5.6em * var(--text-scale, 1));
      -webkit-line-clamp: 4;
    }
  }

  &.big {
    border-radius: 5px;

    .item-price {
      margin-bottom: 0px;
    }

    .name {
      height: auto;
      -webkit-line-clamp: 1;
    }

    .price-basket {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }
}
</style>
