<template>

    <okraina-view :class="'okraina-v-purchase-detail'" @refresh="refresh" ref="view" :hideMenu="true"
        :contentHorizontalPadding="19">

        <template v-slot:header>
            <h1>
                Покупка 
                <div class="date">от {{ dateInsert }}</div>
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="purchase">
                    <div class="block">

                        <div class="props">
                            <div class="prop">
                                <div class="name">Магазин</div>
                                <div class="value">{{ purchase.SHOP }}</div>
                            </div>  
                            <div class="prop">
                                <div class="name">Время покупки</div>
                                <div class="value">{{ purchase.TIME }}</div>
                            </div>
                            <div class="prop" v-if="purchase.DISCOUNT">                                
                                <div class="name">Скидка</div>
                                <div class="value" v-html="tools.formatPrice(purchase.DISCOUNT, { supDecimals: false, supCurrency: false,supCurrency: false })"></div>  
                            </div> 
                            <div class="prop">
                                <div class="name">Сумма покупки</div>
                                <div class="value" v-html="tools.formatPrice(purchase.SUM, { supDecimals: false, supCurrency: false })"></div>
                            </div> 
                        </div>
                    </div>

                    <h2>Состав покупки</h2>

                    <div class="block items">

                        <div class="item" v-for="item in purchase.PRODUCTS" :key="item.article">
                            <div>
                                <div class="name">{{ item.Numenclature }}</div>

                                <div class="basket">
                                    <div class="price">
                                        <div v-html="tools.formatPrice(item.Price, {
                                            supDecimals: false,
                                            supCurrency: false,
                                        })"></div>
                                        <div> за {{ item.Measure }}</div>
                                    </div>
                                    <div class="quantity">
                                        x {{ item.Count }} {{ item.Measure }}
                                    </div>
                                    <div class="sum">
                                        <div v-html="tools.formatPrice(item.Amount+item.DiscountAmount, {
                                        supDecimals: false,
                                        supCurrency: false,
                                    })"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </template>

                <template v-else>

                    <okraina-not-found caption="Покупка не найдена">

                        <div class="btn btn1 slim" @click="retry">Повторить</div>

                    </okraina-not-found>
                </template>

            </template>

        </template>

    </okraina-view>

</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaNotFound from "@/components/not.found.vue";

import rest from "@/plugins/rest";
import moment from "moment";

import tools from "@/plugins/tools";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/purchase.detail.js";
export default {

    name: "okraina-v-purchase-detail",

    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaNotFound,
    },

    data() {
        return {
            loading: true,
            timer: 0,
            purchase: false,
            moment: moment,
            tools: tools,
            canShow: false,
            showChangePaymentPopup: false
        }
    },

    props: {
        id: {
            type: String,
            default() {
                return "";
            },
        },
    },

    computed: {

        dateInsert() {
            if (!this.purchase) {
                return "";
            }
            return moment(this.purchase.DATE, 'DD.MM.YYYY').format("DD MMMM YYYY");
        },

    },

    watch: {
        id: function () {
            this.loadData();
        },
        purchase: function () {
            this.setMeta();
        }
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {

            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("purchases.getbyid", {
                    method: "post",
                    data: {
                        ID: this.id,
                        NUMBER:'7040020011788'
                    },
                })
                .then((data) => {
                    console.log(data);
                    if (data.SUCCESS) {
                        this.purchase = data.ITEM;
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    this.loading = false;
                });
        },

        /**
        * Устанавливает мета данные страницы
        */
        setMeta() {
            if (this.purchase) {
                let metaInfo = {};
                metaInfo.DATE_BUY = this.purchase.DATE;
                meta.write(metaTemplates, metaInfo);
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve = false) {
            this.loadData(resolve);
        },

        /**
         * Повторная попытка загрузить покупку
         */
        retry() {
            this.loading = true;
            this.loadData();
        },

    },

    created() {
        this.loadData();
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    /**
     * Обработка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setMeta();
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}
</script>

<style lang="scss">
.okraina-v-purchase-detail {
    .view-inner {
        .view-header {
            .header-center {
                overflow: visible;
            }
        }
    }

    h1 {
        position: relative;

        .date {
            position: absolute;
            top: 75%;
            font-size: 12px;
        }
    }

    .block {
        padding: 12px 8px;
        border-radius: 8px;
        background-color: var(--color-block-background);
        margin-bottom: 20px;

        .title {
            position: relative;
            font-size: 12px;
            color: var(--color-text-dark);
            font-weight: 600;
            margin-bottom: 8px;

            .status {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                padding: 5px 8px;
                border-radius: 4px;
                font-size: 10px;
                font-weight: 500;
                color: var(--color-text-dark);
                line-height: 1;
                max-width: 150px;
                text-align: right;

            }
        }
    }

    .prop {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 15px;
        align-items: center;
        border-bottom: 1px solid var(--color-color7);
        padding: 8px 0px;

        .name {

            font-size: 12px;
            color: var(--color-text-dark);
        }

        .value {
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            color: var(--color-text-dark);
            overflow-wrap: break-word;
            min-width: 0;
        }
    }

    .props {
        .prop:last-of-type {
            border-bottom: 0px;
        }
    }


    .okraina-price {
        font-size: 12px;
        font-weight: 500;
    }

    .btn-pay-wrap {
        margin-top: 20px;
        padding: 0px 20px;
        text-align: center;

        .btn-pay {
            min-width: 200px;
        }
    }

    .pay-notes {
        margin-top: 20px;
        font-size: 12px;
    }


    .items {

        .item {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 15px;

            border-bottom: 1px solid var(--color-color7);
            padding: 8px 0px;

            &:last-of-type {
                border-bottom: 0px;
            }

            .name {
                font-size: 12px;
                font-weight: 500;

                color: var(--color-text-dark);
            }

            .params {

                .param {
                    margin-top: 5px;
                    display: inline-block;
                    font-size: 11px;
                    font-weight: 500;

                    .label {
                        color: var(--color-text-title);

                        &:after {
                            content: " ";
                        }
                    }

                    .value {
                        font-weight: 500;
                        ;
                    }
                }
            }

            .basket {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 0.35fr 0.3fr 0.35fr;
                column-gap: 10px;
                .price{
                    display: flex;
                    div:last-child{
                        padding-left: 5px;
                    }
                }
                .quantity {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                }

                .sum {
                    text-align: right;
                }
            }
        }
    }

    .btns-wrap {
        padding: 0px 21px;

        .btn {
            margin-bottom: 15px;
        }
    }



}
</style>