<template>

    <okraina-view :class="'okraina-v-pp-l'" :refreshDisabled="true" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Покупки в магазинах
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <div ref="root">
                <!--Индикатор загрузки-->
                <transition>
                    <okraina-loader-page v-show="loading || !canShow" />
                </transition>
            
                <template v-if="!loading && canShow">
                    <!--Если список покупок не пуст-->
                    <template v-if="result.ITEMS">
                        <div class="items">
                            <div v-for="item in result.ITEMS" :key="item.ID">
                            
                                <div class="item" @click.prevent="goToPurchase(item)" v-ripple>
                                    <div class="top">
                                        <div class="number">Покупка от {{ item.DATE }}</div>
                                        <div class="status" style="background-color: rgb(86, 160, 0);color: rgb(255, 255, 255);">Покупка</div>
                                    </div>
                                
                                    <div class="inner">
                                        <div class="props">
                                            <div class="prop">
                                                <div class="name">Магазин:</div>
                                                <div class="value">{{ item.SHOP}}</div>
                                            </div>
                                        </div>
                                    </div>
                                                                
                                </div>
                            </div>
                        </div>
                        <div ref="pager" class="pager"></div>
                        <!-- Подгрузка следюущей страницы --> 
                        <template v-if="loadingPaging">
                            <div class="text-center">
                                <okraina-loader-inline />
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="orders-empty">
                            <div class="caption">Список покупок пуст</div>
                        </div>
                    </template>
                
                </template>
            
            </div>

        </template>

</okraina-view>

</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/purchase.list.js";
export default {
    name: "okraina-v-personal-purchase-list",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaLoaderInline
    },
    data() {
        return{
            loadingPaging: false,
            loadingReload: true,
            result:{},
            loading: true,
            loadingError:true,
            canShow: false,
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE_DEFAULT: 10,
                PAGE_SIZE: 10,
                PAGES_COUNT: 1,
                TOTAL: 1,
            },
        };
    },
    methods: {

        /**
         * Загружает данные
         */
         loadData() {

            //Вычисляем текущее кол-во элементов на странице
            this.pager.PAGE_SIZE = this.pager.PAGE_SIZE_DEFAULT * this.pager.NUM_PAGE;

            rest
            .call("purchases.getlist", {
                method: "post",
                data: {
                    NUMBER:"7040020011788",
                    PAGE_SIZE: this.pager.PAGE_SIZE,
                    PAGE:this.pager.NUM_PAGE
                },
            })
            .then((data) => {

                this.loadingError = !data.SUCCESS;

                if (data.SUCCESS) {
                    this.result = data;
                } else {
                    //показываем сообщение об ошибке
                    bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                }
                this.pager.TOTAL = data.PAGER.total;
            })
            .finally(() => {
                this.loadingPaging = false;
                this.loading = false;
            });
        },

        /**
         * Скрол экрана
         */
         onScroll(e) {
            if (!this.$refs.pager) {
                return;
            }
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingPaging &&
                    !this.loading &&
                    this.pager.PAGE_SIZE < this.pager.TOTAL
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

        //Переход к деталке покупки
        goToPurchase(item){
            setTimeout(() => {
                this.$router.push({ name: "purchase-detail", params: { id: item.ID } });
            }, 400);
        },

        /**
        * Устанавливает мета данные страницы
        */
        setMeta() {
            meta.write(metaTemplates);
        },

        /**
         * Повторная попытка загрузки
         */
         retryLoad() {
            this.loadData();
        },
    },

    created() {
        this.loadData();
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },


    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
     beforeRouteLeave() {
        //запоминаем скрол
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обработка переход входом на экран
     */
     beforeRouteEnter(to, from, next) {

        next(vm => {
            vm.setMeta();
            vm.$refs.view.onBeforeRouteEnter();
        });

    },
}
</script>
<style lang="scss">
.okraina-v-pp-l{
    .view-content {
        padding-bottom: 40px;
    }

    .orders-empty {
        text-align: center;
        height: 100%;
        background-color: var(--color-block-background);
        border-radius: 50%;
        padding: 100px 0px;
        margin-top: 70px;

        .caption {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #505050;
        }

        .details {
            font-size: 13px;
            line-height: 140%;
            color: rgba(51, 51, 51, 0.8);
        }
    }

    .item {
        margin-bottom: 10px;
        background-color: var(--color-block-background);
        border-radius: 8px;

        .top {
            position: relative;
            padding: 12px 8px 0px;
            /*border-bottom: 1px solid rgba(var(--color-text-primary-rgb), 0.1);*/

            .number {
                font-size: 14px;
                color: var(--color-color1);
                font-weight: 500;

            }

            .date {
                font-size: 10px;
                font-weight: 500;
            }

            .status {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                padding: 5px 8px;
                border-radius: 4px;
                font-size: 10px;
                font-weight: 500;
                color: var(--color-text-dark);
                line-height: 1;
                max-width: 150px;
                text-align: right;

            }

        }

        .inner {
            padding: 8px 8px 3px;
        }

        .prop {
            .name {
                font-size: 12px;
                color: var(--color-text-gray);
            }

            .value {
                font-size: 13px;
                margin-bottom: 5px;
            }
        }

        .bottom {
            position: relative;
            padding: 8px;

            .payment {
                font-size: 12px;
                font-weight: 500;
                max-width: 220px;
            }

            .price {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
</style>