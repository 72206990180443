import { mapGetters } from "vuex";

export default {
    
  computed: {

    ...mapGetters({
        siteSettings: "getSiteSettings"
    }),

    canBuy() {
      //если нет цены
      if (!this.item.PRICE || this.item.PRICE.DISCOUNT_VALUE == 0) {
        return false;
      }

      //если временно недоступен
      if (
        this.item.PROPS.TEMPORARILY_NOT_AVAILABLE &&
        this.item.PROPS.TEMPORARILY_NOT_AVAILABLE.XML_ID == "Y"
      ) {
        return false;
      }

      //если включен кол-венный учёт и остаток 0
      if (
        this.item.CATALOG.CHECK_QUANTITY == "Y" &&
        this.item.CATALOG.QUANTITY <= 0
      ) {
        return false;
      }

      return true;
    },

    /**
     * Можно использовать функционал "Привозите больше"
     */
    canNeedMore() {
      return this.siteSettings && this.siteSettings.NEED_MORE_ENABLED == "Y" && this.siteSettings.FORM_NEED_MORE > 0 && this.item.PRICE;
    },

    /**
     * Можно ли подписаться при отсутствии товара
     */
    canSubscribe() {
      return this.item.CATALOG_SUBSCRIBE && this.item.CATALOG_SUBSCRIBE.ENABLED == "Y";
    },

    /**
     * Выбранный вариант (сам товар или его нарезка)
     */
    selectedId() {

      //проверяем есть ли сам товар в корзине
      let basketItem = this.$store.getters.getProductInBasket(
        this.item.FIELDS.ID,
        []
      );
      if (basketItem) {
        return this.item.FIELDS.ID;
      }

      //ищем есть ли в корзине нарезку по весу
      if (this.item.RIFLING) {
        let rifling = this.item.RIFLING.find(
          (r) => {
            let basketItem = this.$store.getters.getProductInBasket(
              this.item.FIELDS.ID,
              [
                {
                  CODE: "RIFLING",
                  NAME: "Нарезка",
                  VALUE: r.FIELDS.NAME,
                },
              ],
            );
            return !!basketItem
          }
        );
        if (rifling) {
          return rifling.FIELDS.ID;
        }
      }

      //ищем есть ли в корзине нарезка как отдельный товар
      if (this.item.RIFLING_PRODUCTS) {
        let rifling = this.item.RIFLING_PRODUCTS.find(
          (r) => {
            let basketItem = this.$store.getters.getProductInBasket(
              r.FIELDS.ID,
              []
            );
            return !!basketItem;
          }
        );
        if (rifling) {
          return rifling.FIELDS.ID;
        }

      }

      //ищем есть ли в корзине товар другого формата
      if (this.item.OTHER_FORMAT_PRODUCTS) {
        let rifling = this.item.OTHER_FORMAT_PRODUCTS.find(
          (r) => {
            let basketItem = this.$store.getters.getProductInBasket(
              r.FIELDS.ID,
              []
            );
            return !!basketItem;
          }
        );
        if (rifling) {
          return rifling.FIELDS.ID;
        }

      }

      return this.item.FIELDS.ID;
    },

    /**
     * Текущая выбранная нарезка или сам товар
     */
    selected() {
      //если задана нарезка по весу
      if (this.item.RIFLING) {
        let rifling = this.item.RIFLING.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return rifling;
        }
      }

      //если задана нарезка как отдельные товары
      if (this.item.RIFLING_PRODUCTS) {
        let rifling = this.item.RIFLING_PRODUCTS.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return rifling;
        }
      }

      //если заданы товары другого формата
      if (this.item.OTHER_FORMAT_PRODUCTS) {
        let rifling = this.item.OTHER_FORMAT_PRODUCTS.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return rifling;
        }
      }

      return this.item;
    },

    /**
     * Есть ли нарезка
     */
    hasRifling() {
      if (this.item.RIFLING && this.item.RIFLING.length > 0 ||
        this.item.RIFLING_PRODUCTS && this.item.RIFLING_PRODUCTS.length > 0 ||
        this.item.OTHER_FORMAT_PRODUCTS && this.item.OTHER_FORMAT_PRODUCTS.length > 0) {
        return true;
      }
      return false;
    },

    /**
     * ВЫбрана ли сейчас именно нарезка
     */
    isRiflingSelected() {

      //если задана нарезка по весу
      if (this.item.RIFLING) {
          let rifling = this.item.RIFLING.find(
              (r) => r.FIELDS.ID == this.selectedId
          );
          if (rifling) {
              return true;
          }
      }

      //если задана нарезка как отдельные товары
      if (this.item.RIFLING_PRODUCTS) {
          let rifling = this.item.RIFLING_PRODUCTS.find(
              (r) => r.FIELDS.ID == this.selectedId
          );
          if (rifling) {
              return true;
          }
      }
      return false;
    },


    /**
     * Данные для добавления в корзину
     */
    basketData() {
      //если задана нарезка по весу
      if (this.item.RIFLING) {
        let rifling = this.item.RIFLING.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return {
            productId: this.item.FIELDS.ID,
            params: [
              {
                CODE: "RIFLING",
                NAME: "Нарезка",
                VALUE: rifling.FIELDS.NAME,
              },
            ],
          };
        }
      }

      //если задана нарезка как отдельные товары
      if (this.item.RIFLING_PRODUCTS) {
        let rifling = this.item.RIFLING_PRODUCTS.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return {
            productId: rifling.FIELDS.ID,
            params: [],
          };
        }
      }

      //если заданы товары другого формата
      if (this.item.OTHER_FORMAT_PRODUCTS) {
        let rifling = this.item.OTHER_FORMAT_PRODUCTS.find(
          (r) => r.FIELDS.ID == this.selectedId
        );
        if (rifling) {
          return {
            productId: rifling.FIELDS.ID,
            params: [],
          };
        }
      }


      return {
        productId: this.item.FIELDS.ID,
        params: [],
      };
    },

    checkQuantity() {
      return this.selected.CATALOG
        ? this.selected.CATALOG.CHECK_QUANTITY
        : this.item.CATALOG.CHECK_QUANTITY;
    },

    maxQuantity() {
      if (this.selected.CATALOG) {
        return this.selected.CATALOG.QUANTITY > 0
          ? parseInt(this.selected.CATALOG.QUANTITY)
          : 0;
      }

      return this.item.CATALOG.QUANTITY > 0
        ? parseInt(this.item.CATALOG.QUANTITY)
        : 0;
    },

    preorder() {
      return this.selected.CATALOG
        ? this.selected.CATALOG.CAN_PREORDER
        : this.item.CATALOG.CAN_PREORDER;
    },

    maxQuantityMessage() {
      if (this.selected.CATALOG) {
        return this.selected.CATALOG.MAX_QUANTITY_MESSAGE
          ? this.selected.CATALOG.MAX_QUANTITY_MESSAGE
          : "";
      }

      return this.item.CATALOG.MAX_QUANTITY_MESSAGE
        ? this.item.CATALOG.MAX_QUANTITY_MESSAGE
        : "";
    },
  }

}