<template>
  <div class="okraina-cp-bb" v-ripple.touchstart="'rgba(255, 255, 255, 0.15)'"
    :class="{ in: quantity > 0 || cartMode, preorder: preorder == 'Y' }" @click="onAddClick">
    <span v-if="quantity == 0 && !cartMode" class="add">
      <template v-if="hasRifling">Выбрать</template>
      <template v-else>
        <template v-if="preorder == 'Y'">Предзаказ</template>
        <template v-else>В корзину</template>

      </template>

    </span>
    <template v-else>
      <div class="minus" @click.stop="onMinusClick">
        <div class="decrease"></div>
      </div>
      <span class="cnt">{{ quantity }}</span>
      <div class="plus" @click.stop="onPlusClick">
        <div class="increase"></div>
      </div>
    </template>
  </div>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from "../../plugins/yandex.metrika";

export default {
  name: "okraina-catalog-product-buy-btn",
  components: {},
  emits: ["openRiflingSelect"],
  data() {
    return {
      quantity: 0,
      timer: 0
    }
  },
  props: {
    productId: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    checkQuantity: {
      type: String,
      default() {
        return "N";
      },
    },
    maxQuantity: {
      type: Number,
      default() {
        return 0;
      },
    },
    maxQuantityMessage: {
      type: String,
      default() {
        return ""
      }
    },
    preorder: {
      type: String,
      default() {
        return "N";
      },
    },
    params: {
      type: Array,
      default() {
        return [];
      },
    },
    recommendationId: {
      type: [String, Number],
      default() {
        return "";
      }
    },
    listMode: {
      type: Boolean,
      default() {
        return false
      }
    },
    hasRifling: {
      type: Boolean,
      default() {
        return false
      }
    },
    cartMode: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
    inBasket() {
      return this.$store.getters.InBasket(this.productId, this.params);
    },
    basketItem() {
      return this.$store.getters.getProductInBasket(
        this.productId,
        this.params
      );
    },
    /**
     * Кол-во в общем хранилище данных - чтобы отслеживать из-менение кол-ва у одного и того же товара если их несколько на странице
     */
    basketQuantity() {
      return this.basketItem ? this.basketItem.quantity : 0;
    }

  },
  watch: {
    basketQuantity: function () {
      if (this.timer == 0) {
        this.quantity = this.basketQuantity;
      }
    }
  },

  methods: {

    /**
     * Первое добавление в корзину
     */
    onAddClick() {
      //если товара ещё нет в корзине
      if (!this.inBasket) {
        //если кнопка в списке товаров и у товара есть нарезка
        if (this.listMode && this.hasRifling) {

          this.$emit("openRiflingSelect");

        } else {
          this.onPlusClick();
        }
      }
    },

    /**
     * Добавление/Увеличение
     */
    onPlusClick() {
      //если включен кол-венный учёт и количество в корзине уже равно или больше чем максимально возможно
      if (
        this.checkQuantity == "Y" &&
        this.quantity >= this.maxQuantity
      ) {
        //показываем сообщение об ошибке
        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: this.maxQuantityMessage ? this.maxQuantityMessage : "Остаток товара " + this.maxQuantity + " шт." });
        return;
      }

      this.quantity += 1;

      this.saveByTimer();
    },

    /**
     * Уменьшение/Удаление
     */
    onMinusClick() {
      if (this.quantity > 0) {
        this.quantity -= 1;
        this.saveByTimer();
      }

    },

    /**
     * Сохранение с задержкой (чтобы учесть несколько одновременных кликов +/-)
     */
    saveByTimer() {

      if (this.timer > 0) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.save();
        this.timer = 0;
      }, 500)

    },

    save() {

      let action = "add";
      if (this.quantity == 0) {
        if (this.basketItem) {
          action = "delete";
        } else {
          return;
        }
      } else {
        if (this.basketItem) {
          action = "update";
        }
      }

      let savedQuantity = this.quantity;
      rest
        .call("basket." + action, {
          method: "post",
          data: {
            PRODUCT_ID: this.productId,
            QUANTITY: this.quantity,
            PARAMS: this.params,
            RID: this.recommendationId
          },
        })
        .then((data) => {

          if (data.SUCCESS) {

            //изменяем кол-во в корзине
            this.$store.commit("updateBasketItem", {
              pid: this.productId,
              params: this.params,
              quantity: savedQuantity
            });

            //событие для ЯндексМетрики
            if (action == "add") {
              metrika.ecommerceAdd({
                id: this.productId,
                quantity: this.quantity
              })
            } else if (action == "delete") {
              metrika.ecommerceRemove({
                id: this.productId
              });
            }

          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            this.quantity = this.basketQuantity;
          }
        });

    }
  },

  created() {
    if (this.basketItem) {
      this.quantity = this.basketItem.quantity;
    }
  },
};
</script>

<style lang="scss">
.okraina-cp-bb {
  position: relative;

  border-radius: 8px;
  text-align: center;
  padding: 7px 7px;
  font-weight: 600;
  font-size: 14px;
  background-color: var(--color-buy-background);
  color: var(--color-buy-color);
  border: 1px solid var(--color-buy-background);

  cursor: pointer;


  &.in {
    background-color: var(--color-buy-background-in);
    color: var(--color-buy-color-in);
    border-color: var(--color-buy-background-in);
    cursor: default;
  }

  &.preorder {
    background-color: var(--color-buy-preorder-background);
    border-color: var(--color-buy-preorder-background);
    color: var(--color-buy-preorder-color);
  }

  .minus {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 35px;
    z-index: 1;
    cursor: pointer;

    .decrease {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-buy-color-in);
      width: 11px;
      height: 2px;
    }
  }




  .plus {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 35px;
    z-index: 2;
    cursor: pointer;

    .increase {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-buy-color-in);
      width: 10px;
      height: 2px;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-buy-color-in);
        height: 10px;
        width: 2px;

      }
    }

  }

}
</style>
