<template>
    <div class="okraina-cp-bbd" :class="{ in: quantity > 0, 'show-price': showPrice, preorder: preorder == 'Y' }">

        <div class="price-wrap">
            <slot name="price"></slot>
        </div>
        <div class="btn-wrap">
            <div class="btn btn-block btn1 btn-basket" v-ripple="'rgba(255, 255, 255, 0.15)'" @click="onAddClick">

                <span v-if="quantity == 0" class="add">
                    <template v-if="preorder == 'Y'">Предзаказ</template>
                    <template v-else>Добавить в корзину</template>
                </span>
                <template v-else>
                    <div class="minus" @click.stop="onMinusClick">
                        <div class="decrease"></div>
                    </div>
                    <span class="cnt">{{ quantity }}</span>
                    <div class="plus" @click.stop="onPlusClick">
                        <div class="increase"></div>
                    </div>
                </template>

            </div>

        </div>

    </div>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from "../../plugins/yandex.metrika";

export default {
    name: "okraina-catalog-product-buy-detail-btn",
    components: {},
    data() {
        return {
            quantity: 0,
            timer: 0
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        checkQuantity: {
            type: String,
            default() {
                return "N";
            },
        },
        maxQuantity: {
            type: Number,
            default() {
                return 0;
            },
        },
        maxQuantityMessage: {
            type: String,
            default() {
                return ""
            }
        },
        preorder: {
            type: String,
            default() {
                return "N";
            },
        },
        params: {
            type: Array,
            default() {
                return [];
            },
        },
        recommendationId: {
            type: [String, Number],
            default() {
                return "";
            }
        },
        modal: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showPrice: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },
    computed: {

        inBasket() {
            return this.$store.getters.InBasket(this.productId, this.params);
        },
        basketItem() {
            return this.$store.getters.getProductInBasket(
                this.productId,
                this.params
            );
        },

        /**
         * Кол-во в общем хранилище данных - чтобы отслеживать из-менение кол-ва у одного и того же товара если их несколько на странице
         */
        basketQuantity() {
            return this.basketItem ? this.basketItem.quantity : 0;
        }
    },
    watch: {
        basketQuantity: function () {
            if (this.timer == 0) {
                this.quantity = this.basketQuantity;
            }
        },
    },

    methods: {
        /**
         * Первое добавление в корзину
         */
        onAddClick() {
            //если товара ещё нет в корзине
            if (!this.inBasket) {
                this.onPlusClick();
            }
        },

        /**
         * Добавление/Увеличение
         */
        onPlusClick() {
            //если товар уже в корзине и включен кол-венный учёт и количество в корзине уже равно или больше чем максимально возможно
            if (
                this.basketItem &&
                this.checkQuantity == "Y" &&
                this.quantity >= this.maxQuantity
            ) {
                //показываем сообщение об ошибке
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: this.maxQuantityMessage ? this.maxQuantityMessage : "Остаток товара " + this.maxQuantity + "шт." });
                return;
            }

            this.quantity += 1;

            this.saveByTimer();
        },

        /**
         * Уменьшение/Удаление
         */
        onMinusClick() {
            if (this.quantity > 0) {
                this.quantity -= 1;
                this.saveByTimer();
            }

        },

        /**
         * Сохранение с задержкой (чтобы учесть несколько одновременных кликов +/-)
         */
        saveByTimer() {

            if (this.timer > 0) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                this.save();
                this.timer = 0;
            }, 500)

        },

        save() {

            let action = "add";
            if (this.quantity == 0) {
                if (this.basketItem) {
                    action = "delete";
                } else {
                    return;
                }
            } else {
                if (this.basketItem) {
                    action = "update";
                }
            }

            rest
                .call("basket." + action, {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.productId,
                        QUANTITY: this.quantity,
                        PARAMS: this.params,
                        RID: this.recommendationId
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        //изменяем кол-во в корзине
                        this.$store.commit("updateBasketItem", {
                            pid: this.productId,
                            params: this.params,
                            quantity: this.quantity
                        });

                        //событие для ЯндексМетрики
                        if (action == "add") {
                            metrika.ecommerceAdd({
                                id: this.productId,
                                quantity: this.quantity
                            })
                        }


                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.quantity = this.basketQuantity;
                    }
                });

        }
    },

    created() {
        if (this.basketItem) {
            this.quantity = this.basketItem.quantity;
        }
    },
};
</script>

<style lang="scss">
.okraina-cp-bbd {

    .price-wrap {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
    }

    .btn-wrap {
        position: relative;

        background: var(--color-color8);

        transition: margin-left 0.3s ease-out, padding-left 0.3s ease-out;
    }

    &.show-price {
        .btn-wrap {
            margin-left: 30%;
            padding-left: 0px;
        }
    }

    .btn-basket {
        position: relative;

        padding: 12px 15px;

        .minus {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            width: 45%;
            z-index: 1;

            .decrease {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: var(--color-buy-color-in);
                width: 11px;
                height: 2px;
            }
        }

        .plus {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            width: 45%;
            z-index: 2;

            .increase {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: var(--color-buy-color-in);
                width: 10px;
                height: 2px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: var(--color-buy-color-in);
                    height: 10px;
                    width: 2px;

                }
            }

        }
    }

    &.preorder {
        .btn-basket {

            background-color: var(--color-buy-preorder-background);
            border-color: var(--color-buy-preorder-background);
            color: var(--color-buy-preorder-color);

        }
    }




}
</style>
