<template>
    <div class="okraina-cashback-info">
        <div class="cashback-block">

            <div v-if="cashback.NEXT_STATUS">
                <div class="how-much">
                    <div class="money" v-html="tools.formatPrice(Math.floor(cashback.HOW_MUCH), {includeDecimals: false})"></div> 
                    <span>- до кешбэка {{cashback.NEXT_STATUS.PERCENT.VALUE}}%</span> 
                    <div class="hint" @click="displayBuy()"><span class="fi fi-question"></span></div>
                </div>
                <div class="buy" v-if="showBuy">
                    <span>Совершите покупку ещё на <b v-html="tools.formatPrice(Math.floor(cashback.HOW_MUCH), {includeDecimals: false})"></b> и получите кешбэк {{cashback.NEXT_STATUS.PERCENT.VALUE}}% в следующем месяце</span>
                </div>
            </div>

            <div v-else-if="cashback.LEVEL == cashback.LEVELS">
                Вы достигли максимального кешбэка
            </div>

            <div class="max-cashback" v-else>
                <p class="congritulations">Поздравляем!</p> У Вас будет максимальный кешбэк со следующего месяца
            </div>  

        </div>
    </div>
</template>

<script>
import tools from "@/plugins/tools";

export default {
    name: "okraina-cashback-info",
    data() {
        return {
            tools,
            showBuy:false
        };
    },
    props:{
        cashback: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods:{
        displayBuy(){
            this.showBuy = this.showBuy == true ? false : true;
        }
    }
}
</script>

<style lang="scss">
    .okraina-cashback-info{
        .cashback-block{
            .buy{
                background-color: #EDEDED;
                border-radius: 10px;
                padding: 10px 15px;
                margin-top: 5px;
                span{
                    color:var(--color-text-dark);
                    font-size: 13px;
                    font-weight: 500;
                    b{
                        .okraina-price,
                        .okraina-price sub{
                            font-size: 13px;
                            font-weight: 700;
                        }
                    }
                }
            }
            .max-cashback,
            .how-much,
            .how-much .okraina-price, 
            .how-much .okraina-price sub{
                font-size: 13px;
                font-weight: 600;
                color:var(--color-text-dark);
                .congritulations{
                    margin-block: 0;
                    color:#7EB100;
                }
            }
            .max-cashback{
                background-color: #FFF;
                border-radius: 10px;
                margin-top: 20px;
                padding: 10px 15px;
            }
            .how-much{
                display: flex;
                align-items:center;
                padding: 5px 15px;
                background-color: #FFF;
                border-radius: 10px;
                .money{
                    padding-right: 5px;
                }
                .hint{
                    margin-left: auto;
                    line-height: 1;
                    span{
                        font-size: 16px;
                        opacity: 0.2;
                    }
                }
                .okraina-price,
                .okraina-price sub
                {
                    color: #7EB100;
                }
            }
        }        
    }
</style>