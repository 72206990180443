<template>
    <okraina-view :class="['okraina-v-pic']" :refreshDisabled="true" ref="view" :hideMenu="true"
        :contentHorizontalPadding="19">

        <template v-slot:header>
            <h1 class="title">
                Узнать информацию о продукте
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <div class="scan" v-if="isNative" @click="scan">

                <div class="picture" :style="{ 'background-image': 'url(' + imageScan + ')' }"></div>

                <div class="text">
                    Отсканируйте код PIC камерой устройства или выберите картинку из галереи
                </div>

            </div>

            <div class="search-wrap">
                <div>
                    <okraina-control-input label="" placeholder="Введите код PIC" v-model="code" />
                </div>
                <div>
                    <div class="btn btn1 btn-block slim" @click="check" :class="loading ? 'wait' : ''" v-ripple>
                        Проверить
                        <div v-if="loading" class="btn-loader"></div>
                    </div>
                </div>
            </div>

            <div class="captcha-wrap" v-if="needCaptcha">
                <okraina-control-input label="" placeholder="Код на картинке" v-model="captchaWord" :captcha="captchaImg" />
            </div>

            <div class="description">
                <h2>Что такое PIC?</h2>

                <p>
                    PIC или Product Individual Code&nbsp;(продуктовый индивидуальный код) – это инструмент контроля качества
                    продукции – наша уникальная разработка, до сих пор не имеющая аналогов на российском рынке.
                </p>

                <p>
                    Это не только внутрипроизводственная система, фиксирующая откуда, когда, в какой партии поступило сырье
                    для каждого нашего продукта, а также кто его принимал, проводил анализ и работал на каждом этапе вплоть
                    до поставки в торговую точку или клиенту при заказе на дом, но и уникальная система обратной связи с
                    покупателем.
                </p>

                <p>
                    Чтобы получить доступ к информации о приобретенном продукте ТМ «Окраина», достаточно указать его
                    индивидуальный код PIC.<br>
                    <br>
                    Уникальный код продукта&nbsp;напечатан на этикетке-термочеке, которая находится на оборотной стороне
                    упаковки или нанесен прямо на колбасную оболочку. В целом, на продукте может быть указано несколько
                    штрих-кодов. PIC&nbsp;размещен с правой стороны или в нижней части этикетки с информацией о продукте
                    всегда на желтом фоне. Количество цифр в нем — 11. Например, PIC&nbsp;— 00002001129.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>

                <p>
                    Ввод PIC&nbsp;(Product Individual Code)&nbsp; откроет Вам доступ к информации о продукте.<br>
                    <br>
                    Здесь же Вы можете дать оценку купленному продукту и высказать любые вопросы по его качеству. Эта
                    информация и Ваши отклики позволят нам оперативно вносить коррективы в свою работу или в работу наших
                    партнеров, чтобы сделать наш продукт еще лучше.
                </p>
                <img :src="imagePic" />
            </div>

            <okraina-sheet v-model:visible="showPicPopup" class="okraina-p-v-wrap" :maxHeight="92">
                <template v-slot:body>
                    <okraina-pic-view :result="result" @goToProduct="goToProduct" />
                </template>
            </okraina-sheet>



        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaSheet from "@/components/sheet.vue";
import okrainaPicView from "@/components/pic/view.vue"


import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/pic.js";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import imageScanPath from "@/assets/images/scan.svg";
import imagePicPath from "@/assets/images/pic.jpg";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-pic",
    components: {
        okrainaView,
        okrainaControlInput,
        okrainaSheet,
        okrainaPicView
    },
    data() {
        return {
            imageScan: imageScanPath,
            imagePic: imagePicPath,
            code: "",
            loading: false,
            needCaptcha: false,
            captchaSid: "",
            captchaImg: "",
            captchaWord: "",
            showPicPopup: false,
            result: false
        };
    },
    computed: {
        ...mapGetters({
            isNative: "isNativePlatform",
        }),
    },

    methods: {

        check() {

            if (this.loading) {
                return;
            }
            this.loading = true;

            //запрашивает автооризацию
            rest
                .call("upc.getbycode", {
                    method: "post",
                    data: {
                        CODE: this.code,
                        RESIZE_OPTIONS: tools.getProductDetailResizeOptions(),
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid,
                    },
                })
                .then((data) => {
                    //если ошибка
                    if (!data.SUCCESS) {
                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.needCaptcha = true;
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaImg = data.CAPTCHA_URL;
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {

                        this.result = data;
                        this.showPicPopup = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
         * Вызов сканера
         */
        scan() {
            bus.emit("OKRAINA_BARCODE_SCANNER_E_OPEN", { resolve: this.onScanResult });
        },

        onScanResult(code) {

            //переводим в нижний регистр
            code = code.toLowerCase();
            
            //ссылка может быть без https
            if (!code.startsWith("http") && code.includes(".ru")) {
                code = "https://" + code;
            }

            if (code.startsWith("http")) {
                const url = new URL(code);
                const rel = url.toString().substring(url.origin.length)

                let pic = "";
                //если ссылка с параметром
                if (rel.includes("?pic")) {

                    const [, paramString] = rel.split('?');
                    let urlParams = new URLSearchParams(paramString);
                    let entries = urlParams.entries();
                    let query = {};
                    for (let [key, value] of entries) {
                        query[key] = value;
                    }
                    pic = query.pic;
                }
                //инаяе если pic зашит в пути
                else if (rel.includes("pic/")) {
                    let parts = rel.split('/');
                    pic = parts[parts.length - 1];
                }

                setTimeout(() => {
                    this.$router.replace({ name: "pic-qr", params: { code: pic } });
                }, 500);

            } else {
                this.code = code;
                this.check();
            }

        },

        hidePicPopup() {
            this.showPicPopup = false;
        },

        goToProduct(product) {
            this.showPicPopup = false;

            setTimeout(() => {
                bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                    id: product.FIELDS.ID,
                    code: product.FIELDS.CODE
                });
            }, 300)

        }
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-pic {

    h1.title {
        line-height: 1.1;
        font-size: 18px;
    }

    .scan {
        position: relative;
        padding: 18px 11px 18px 110px;
        background-color: var(--color-block-background);
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        margin-bottom: 15px;
        min-height: 90px;
        display: grid;
        align-items: center;

        .picture {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            left: 20px;
            width: 74px;
            height: 47px;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

        }

        .text {
            font-size: 13px;
        }

    }

    .search-wrap {
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: 1fr 135px;
        column-gap: 8px;

        .form-control-wrap {
            box-shadow: inset 1px 2px 7px rgba(0, 0, 0, 0.1);
        }
    }

    .captcha-wrap {
        margin-top: -15px;

        .form-control-wrap {
            box-shadow: inset 1px 2px 7px rgba(0, 0, 0, 0.1);
        }
    }

    .description {
        margin: 0px -19px;
        padding: 20px;
        background-color: var(--color-block-background);
    }

}

.okraina-p-v-wrap {
    --color-sheet-bakground: var(--color-view-background) !important;

    .sheet-body {
        padding: 5px 0px 0px !important;
    }

}

.okraina-p-v {
    padding: 0px 8px 0px;
}
</style>