<template>
    <okraina-view :class="'okraina-v-orders'" @refresh="refresh" ref="view" :hideMenu="true" :contentHorizontalPadding="19">

        <template v-slot:header>
            <h1 class="title">
                Мои заказы
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <div ref="root">
                <!--Индикатор загрузки-->
                <transition>
                    <okraina-loader-page v-show="loading || !canShow" />
                </transition>

                <template v-if="!loading && canShow">

                    <!--Если список заказов не пуст-->
                    <template v-if="items.length > 0">
                        <div class="items">
                            <div v-for="item in items" :key="item.ID">

                                <div class="item" @click.prevent="goToOrder(item)" v-ripple>
                                    <div class="top">
                                        <div class="number">Заказ № {{ item.ACCOUNT_NUMBER }}</div>
                                        <div class="date">от {{ dateTransform(item.DATE_INSERT) }}</div>

                                        <div class="status" :style="{
                                            backgroundColor: statusesById[item.STATUS_ID] ? statusesById[item.STATUS_ID].UF_COLOR : 'transparent',
                                            color: statusesById[item.STATUS_ID] && statusesById[item.STATUS_ID].UF_COLOR == '#56A000' ? '#FFFFFF' : 'var(--color-text-dark)'
                                        }">
                                            {{ statusesById[item.STATUS_ID] ?
                                                statusesById[item.STATUS_ID].NAME : "" }}</div>
                                    </div>

                                    <div class="inner">
                                        <div class="props">
                                            <div class="prop" v-if="address(item) != ''">
                                                <div class="name">Адрес доставки:</div>
                                                <div class="value">{{ address(item) }}</div>
                                            </div>
                                            <div class="prop" v-if="deliveryDate(item) != ''">
                                                <div class="name">Дата доставки:</div>
                                                <div class="value">{{ deliveryDate(item) }}</div>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="bottom">
                                        <div class="payment">{{ item.PAY_SYSTEM ? item.PAY_SYSTEM.NAME : "" }}</div>

                                        <div class="price" v-html="tools.formatPrice(price(item))"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div ref="pager" class="pager"></div>
                        <!-- Подгрузка следюущей страницы -->
                        <template v-if="loadingPaging">
                            <div class="text-center">
                                <okraina-loader-inline />
                            </div>
                        </template>

                    </template>
                    <template v-else>
                        <div class="orders-empty">
                            <div class="caption">Список заказов пуст</div>
                            <div class="details">
                                Похоже вы ещё не сделали ни одного заказа
                            </div>
                        </div>
                    </template>

                </template>

            </div>
        </template>

    </okraina-view>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/order.list.js";
import moment from "moment";
import tools from "@/plugins/tools";

import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaView from "@/components/view2.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

export default {
    name: "okraina-v-orders",
    components: {
        okrainaLoaderPage,
        okrainaView,
        okrainaLoaderInline,

    },
    emits: ["update:loading"],
    data() {
        return {
            loadingPaging: false,
            loadingReload: true,
            loading: true,
            items: [],
            statuses: [],
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 10,
                PAGES_COUNT: 1,
            },
            tools: tools,
            canShow: false
        }
    },
    computed: {
        statusesById() {
            let statuses = {};
            this.statuses.forEach((s) => {
                statuses[s.ID] = s;
            })

            return statuses;
        },

    },
    props: {},
    watch: {},
    methods: {

        /**
         * Трансформируем дату
         */
        dateTransform(date) {
            return moment(date, 'DD.MM.YYYY').format("DD MMMM YYYY");
        },

        /**
         * Адрес доставки по заказу
         */
        address(item) {

            let propFullAddres = item.PROPS.find(p => p.CODE == "FULL_ADDRESS");
            if (propFullAddres) {
                return propFullAddres.VALUE;
            }


            let codes = ['LOCATION', 'HOUSE', 'CORPUS', 'APARTMENT'];

            let arParts = [];
            codes.forEach((code) => {
                let prop = item.PROPS.find(p => p.CODE == code);
                if (prop && prop.VALUE_TEXT) {
                    let pre = "";
                    if (code == 'HOUSE') {
                        pre = 'д. ';
                    } else if (code == 'CORPUS') {
                        pre = 'кор. ';
                    } else if (code == 'APARTMENT') {
                        pre = 'кв.. ';
                    }
                    arParts.push(pre + prop.VALUE_TEXT)
                }

            })

            return arParts.join(', ');

        },

        /**
         * Дата / интервал доставки
         */
        deliveryDate(item) {
            let propDate = item.PROPS.find(p => p.CODE == 'DELIVERY_DATE');
            let propTime = item.PROPS.find(p => p.CODE == 'DELIVERY_TIME');
            let arParts = [];

            if (propDate && propDate.VALUE) {
                arParts.push(this.dateTransform(propDate.VALUE));
            }

            if (propTime) {
                arParts.push(propTime.VALUE_TEXT);
            }

            return arParts.join(', ');
        },

        price(item) {

            return parseFloat(item.PRICE);
        },


        goToOrder(item) {
            setTimeout(() => {
                this.$router.push({ name: "order-detail", params: { id: item.ID } });
            }, 400);
        },

        /**
         * Загружает данные
        */
        loadData(resolve = false) {
            if (!this.loadingPaging) {
                this.loading = resolve ? false : true;
            }
            rest
                .call("order.getlist", {
                    method: "post",
                    data: {
                        NAV_PARAMS: this.pager,
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                            this.statuses = data.STATUSES;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            if (!this.$refs.pager) {
                return;
            }
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingPaging &&
                    !this.loading &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve = false) {
            this.pager.NUM_PAGE = 1;
            this.loadData(resolve);
        },
        /**
        * Устанавливает мета данные страницы
        */
        setMeta() {
            meta.write(metaTemplates);
        },

        /**
         * Обработка создания нового заказа
         */
        onNewOrder() {
            this.refresh();
        },

        /**
         * Обработка события оплаты заказа
         */
        onOrderPayd(data) {
            //ищим заказ в текущем списке
            let item = this.items.find(i => i.ID == data.id);
            if (item) {
                this.syncOrder(item);
            }

        },

        /**
         * Обработка отмены заказа
         */
        onOrderCanceled(data) {
            //ищим заказ в текущем списке
            let item = this.items.find(i => i.ID == data.id);
            if (item) {
                this.syncOrder(item);
            }
        },

        /**
         * Синхронизируем данные заказа
         */
        syncOrder(item) {

            rest
                .call("order.getlist", {
                    method: "post",
                    data: {
                        FILTER: {
                            ID: item.ID
                        },
                    },
                })
                .then((data) => {
                    let index = this.items.indexOf(item);
                    if (data.SUCCESS) {
                        if (data.ITEMS.length == 1) {
                            this.items[index] = data.ITEMS[0];
                        }
                    }
                });


        },

        /**
         * Обработка просмотра заказа
         */
        onOrderView(order) {
            //здесь мы можем синхронизировать заказ, если статус изменился
            let item = this.items.find(i => i.ID == order.FIELDS.ID);
            if (item) {
                if (item.STATUS_ID != order.FIELDS.STATUS_ID) {
                    this.syncOrder(item);
                }
            }
        },

    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    mounted() {

        bus.on("OKRAINA_ORDER_E_NEW_ORDER", this.onNewOrder);
        bus.on("OKRAINA_ORDER_E_PAYED", this.onOrderPayd);
        bus.on("OKRAINA_ORDER_E_CANCELED", this.onOrderCanceled);
        bus.on("OKRAINA_ORDER_E_VIEW", this.onOrderView);

        //подписываемся на скролинг экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);


    },

    beforeUnmount() {

        bus.off("OKRAINA_ORDER_E_NEW_ORDER", this.onNewOrder);
        bus.off("OKRAINA_ORDER_E_PAYED", this.onOrderPayd);
        bus.off("OKRAINA_ORDER_E_CANCELED", this.onOrderCanceled);
        bus.off("OKRAINA_ORDER_E_VIEW", this.onOrderView);

        //отписываесмся от скролинга экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
     beforeRouteLeave() {
        //запоминаем скрол
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обработка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        //запишем мета данные страницы
        meta.write(metaTemplates);
        //восстанавилваем скрол
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}
</script>

<style lang="scss">
.okraina-v-orders {

    .view-content {
        padding-bottom: 40px;
    }

    .orders-empty {
        text-align: center;
        height: 100%;
        background-color: var(--color-block-background);
        border-radius: 50%;
        padding: 100px 0px;
        margin-top: 70px;

        .caption {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #505050;
        }

        .details {
            font-size: 13px;
            line-height: 140%;
            color: rgba(51, 51, 51, 0.8);
        }
    }

    .item {
        margin-bottom: 10px;
        background-color: var(--color-block-background);
        border-radius: 8px;

        .top {
            position: relative;
            padding: 12px 8px 0px;
            /*border-bottom: 1px solid rgba(var(--color-text-primary-rgb), 0.1);*/

            .number {
                font-size: 14px;
                color: var(--color-color1);
                font-weight: 500;

            }

            .date {
                font-size: 10px;
                font-weight: 500;
            }

            .status {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                padding: 5px 8px;
                border-radius: 4px;
                font-size: 10px;
                font-weight: 500;
                color: var(--color-text-dark);
                line-height: 1;
                max-width: 150px;
                text-align: right;

            }

        }

        .inner {
            padding: 8px 8px 3px;
        }

        .prop {
            .name {
                font-size: 12px;
                color: var(--color-text-gray);
            }

            .value {
                font-size: 13px;
                margin-bottom: 5px;
            }
        }

        .bottom {
            position: relative;
            padding: 8px;

            .payment {
                font-size: 12px;
                font-weight: 500;
                max-width: 220px;
            }

            .price {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
        }


    }
}
</style>